<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-toolbar-title class="headline font-weight-bold">
        Additional Storage
      </v-toolbar-title>
    </v-app-bar>

    <div class="storage mt-12">
      <v-text-field
        v-model="form.price"
        label="Extra storage price"
        :disable="loading"
        class="mt-2"
        required
        outlined
        flat
      />

      <v-btn
        color="primary"
        :loading="loading"
        @click="updateExtraStoragePrice()"
        depressed
      >
        Update price
      </v-btn>
    </div>

    <v-snackbar
      v-model="snackbar"
      timeout="1500"
      color="primary accent-4"
      bottom
      right
    >
      Price updated
      <template v-slot:action="{ attrs }">
        <v-btn warning text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AdditionalStorage',

  data() {
    return {
      loading: false,
      snackbar: false,
      form: {
        price: 0.0,
      },
    }
  },

  created() {
    this.fetchExtraStoragePrice()
  },

  methods: {
    ...mapActions('plan', {
      getExtraStorage: 'getExtraStorage',
      updateExtraStorage: 'updateExtraStorage',
    }),

    async fetchExtraStoragePrice() {
      this.loading = true
      const { data } = await this.getExtraStorage()
      this.loading = false

      this.form.price = data.data.content
    },

    async updateExtraStoragePrice() {
      this.loading = true
      await this.updateExtraStorage({ price: this.form.price })
      this.loading = false
      this.snackbar = true
    },
  },
}
</script>

<style lang="scss" scoped>
.storage {
  width: 400px;
}
</style>
